 

import styled from 'styled-components'
// const LoginCard = styled.div`
// width: 60%;
// margin-top:70px
// max-width: 70dvw;
// padding: 15px;
// margin: auto;
// `
// export default LoginCard
export  const LoginCard = styled.div`
  width: 60%;
  max-width: 70vw;
  padding: 10px;
  margin-bottom:20px;  
  @media (max-width: 768px) {
    width: 80%;
  }
`;

export  const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export  const StyledUsernameIcon = styled.i`
  /* Add styles for username icon */
  margin-right: 10px; /* Adjust margin as needed */
`;

export  const StyledPasswordIcon = styled.i`
  /* Add styles for password icon */
  margin-right: 10px; /* Adjust margin as needed */
`;

export const ContainerWithBackground = styled.div`
  display: flex;
  background: linear-gradient(to right, #fff 50%, rgb(92, 94, 159) 50%);
  border-radius: 2%;
`;

// export const LeftCol = styled.div`
//   flex: 1;
//   padding: 0px;
//   background-color: rgb(92, 94, 159);
// `;

export const RightCol = styled.div`
  flex: 1;
  padding: 30px;
  // background-color: rgb(92, 94, 159);
  // border: 1px solid;
  border-radius: 2%;
`;

export const ContainerLogin = styled.div`
  padding: 20px;
   border-radius: 2%;
   background-color: #fff;
`;