// Login.js
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LoginCard,
  StyledImage,
  StyledUsernameIcon,
  StyledPasswordIcon,
  ContainerWithBackground,
  LeftCol,
  RightCol,
  ContainerLogin,
} from "./authstyle/LoginCard";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { clearMessage } from "../../store/slices/messageslice";
import { login } from "../../store/slices/authslice";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import BackgroundImage from "../../asset/bg.jpg";
import Logo from "../../asset/logowhitebg.png";
import { toast } from "react-hot-toast";
import { BsEye, BsEyeSlash } from "react-icons/bs";

import "./login.css";
import { BsEmojiSmileUpsideDown } from "react-icons/bs";
import { setLanguage } from "../../store/slices/languageSlice";
import { useTranslation } from "react-i18next";
const Login = (mode) => {
  const dispatch = useDispatch();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false); //showpasswoed toggle
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const onSubmit = (data) => {
    setLoading(true);

    dispatch(login(data))
      .unwrap()
      .then(() => {
        // Set cookie when user successfully logs in
        // document.cookie = "isLoggedIn=true; path=/"; // Set a cookie named 'isLoggedIn' with value 'true'
        navigate("/");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    dispatch(clearMessage());
    if (isLoggedIn || document.cookie.includes("isLoggedIn=true")) {
      navigate("/");
    }
    if (message) {
      toast.error(message);
    } else {
      dispatch(clearMessage());
    }
  }, [dispatch, isLoggedIn, message]);
  const changeLanguageHandler = (e) => {
    dispatch(setLanguage(e));
  };
  const { t } = useTranslation("global");

  return (
    <>
      <div className="container-fluid ">
        <Row>
          <Col md="5" sm="12">
            <RightCol md={7}>
              <ContainerLogin>
                <div className="text-center">
                  <img
                    className=" rounded-4"
                    width="40%"
                    src={Logo}
                    alt="logo"
                  />
                  <div className="h5 mt-2">{t("CONNECTEZ-VOUS")} </div>
                </div>
                <div
                  className={`container text-white d-flex justify-content-center `}
                >
                  <button
                    className={`btn btn-primary m-1 `}
                    onClick={() => changeLanguageHandler("en")}
                  >
                    English
                  </button>
                  <button
                    className={`btn btn-primary  m-1 `}
                    onClick={() => changeLanguageHandler("fr")}
                  >
                    French
                  </button>
                </div>

                <Form onSubmit={handleSubmit(onSubmit)} className="mt-4">
                  <Form.Group>
                    <Form.Label className="fw-bold">
                      {t("Nom d'utilisateur")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Saisissez votre nom d'utilisateur"
                      {...register("username", {
                        required: t("Veuillez saisir votre nom d'utilisateur"),
                      })}
                    />

                    {errors.username && (
                      <p className="text-danger">{errors.username.message}</p>
                    )}
                  </Form.Group>
                  <Form.Group className="mt-3">
                    <Form.Label className="fw-bold">
                      {t("Mot de passe")}
                    </Form.Label>

                    <div className="d-flex align-items-center">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Entrer le mot de passe"
                        {...register("password", {
                          required: t("Veuillez entrer le mot de passe"),
                          minLength: {
                            value: 6,
                            message: "Veuillez saisir au minimum 6 caractères",
                          },
                          maxLength: 20,
                        })}
                      />

                      {/* toogle button  */}
                      <button
                        style={{
                          position: "absolute",
                          left: "34%",
                          border: "none",
                        }}
                        type="button"
                        className="btn  me-0  "
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <BsEyeSlash /> : <BsEye />}
                      </button>
                    </div>
                    {errors.password && (
                      <p className="text-danger">{errors.password.message}</p>
                    )}
                  </Form.Group>
                  <Row className="mt-2 justify-content-between">
                    <Col md={9}>
                      <div className="d-flex justify-content-between mb-3 mt-3">
                        <Form.Check
                          type="checkbox"
                          label={t("Se souvenir de moi")}
                          className=""
                        />
                        {/* <Link to="/forgetpassword" className="float-right text-decoration-none"  >Mot de passe oublié
                        ?</Link> */}
                      </div>
                    </Col>
                  </Row>

                  <Button
                    variant="primary"
                    type="submit"
                    className="btn-block w-100 mt-1 "
                    disabled={loading}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm me-2"></span>
                    )}
                    <span className="fw-bold">{t("Se connecter")}</span>
                  </Button>
                </Form>
              </ContainerLogin>
            </RightCol>
          </Col>
          <Col md="7" sm="12">
            {" "}
            <div className="half-page-image">
              <div style={{ backgroundImage: `url('${BackgroundImage}')` }}>
                <div className="overlay">
                  <div className="quotes">
                    <p className="short-about fw-bold mt-3">
                      <span style={{ fontSize: "15px", color: "red" }}>
                        &#10077;{" "}
                      </span>
                      {t("paragrap")}
                      <span style={{ fontSize: "15px", color: "red" }}>
                        {" "}
                        &#10078;
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="container-fluid">
        <footer className="py-2">
          <p className="text-center  fw-bold footer-color">
            {t("footer-text")}
          </p>
        </footer>
      </div>
    </>
  );
};

export default Login;
