import React, { useState } from "react";
import { TbBellFilled } from "react-icons/tb";
import { MdMessage } from "react-icons/md";
import sidestyle from "./sidebar.module.css";
import { BiLogOut } from "react-icons/bi";
import { logout } from "../../store/slices/authslice";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import { IoPersonCircle } from "react-icons/io5";
import { Container, Dropdown, Navbar } from "react-bootstrap";
import { Form, FormControl } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./header.css";
import { useLocation } from "react-router-dom";
import { adminnavigationmenu } from "./contant";
import { setLanguage } from "../../store/slices/languageSlice";
import { useTranslation } from "react-i18next";

const Header = ({ toggleSidebar }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [username, setUserName] = React.useState("");
  const navigate = useNavigate();

  const [usertypenavigation, setUserTypeNavigation] = useState([]);
  React.useEffect(() => {
    setUserName(user?.username || user);

    setUserTypeNavigation(adminnavigationmenu);
  }, [user]);
  const location = useLocation();
  const [openSubmenuId, setOpenSubmenuId] = useState(null);
  const toggleSubmenu = (submenuId) => {
    setOpenSubmenuId((prevId) => (prevId === submenuId ? null : submenuId));
  };

  const handleDropdownSelect = (eventKey) => {
    if (eventKey === "changepassord") {
      navigate("/profile");
    }
  };
  const changeLanguageHandler = (e) => {
    dispatch(setLanguage(e));
  };
  const { t } = useTranslation("global");
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

  return (
    <>
      <Navbar className="my-3" style={{ backgroundColor: "rgb(92, 94, 159)" }}>
        <Container className="container-fluid">
          <Navbar.Brand>
            <Form inline className="d-flex align-items-center">
              {/* <div class="wrapper">
                <div class="searchBar">
                  <input id="searchQueryInput" type="text" name="searchQueryInput" placeholder="Recherche" />
                  <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit">
                      <FaSearch size={14} className="text-secondary" />
                  </button>
                </div>
              </div> */}
            </Form>
          </Navbar.Brand>
          <Navbar.Collapse className="justify-content-end mx-2">
            <Navbar.Text>
              <div className="d-flex align-items-center">
                <div className="text-white">
                  <select
                    className="custom-select custom-select-lg me-4 btn btn-primary"
                    onChange={(e) => changeLanguageHandler(e.target.value)}
                    value={selectedLanguage}
                  >
                    <option selected className="btn btn-light" value="fr">French</option>
                    <option className="btn btn-light" value="en">English</option>
                  </select>
                </div>

                <Dropdown onSelect={handleDropdownSelect}>
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className="custom-toggle"
                  >
                    <div className="d-flex align-items-center">
                      <IoPersonCircle size={25} className="text-white me-3" />
                      <div className="text-white">
                        {username ? `${username}  ` : "User@gmail.com" + " "}
                      </div>
                    </div>
                  </Dropdown.Toggle>

                  {/* <Dropdown.Menu>  
                    <Dropdown.Item eventKey="changepassord">Paramètre</Dropdown.Item>
                  </Dropdown.Menu> */}
                </Dropdown>
              </div>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
