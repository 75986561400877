import moment from "moment";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import { MyAgendaEvent } from "./CustomAgenda";
import {
  CustomDay,
  CustomMonthHeader,
  CustomWeekHeader,
} from "./calendarutils";
import { messages } from "../labels";
import React, { useEffect } from "react";
import "./calendar.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const localizer = momentLocalizer(moment);

const MainCalendar = ({ allEvents, calendarstyle, handleSelectScheduler }) => {
  const [currentView, setCurrentView] = React.useState("Mois");
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [showCalendarOverlay, setShowCalendarOverlay] = React.useState(false);
  const {selectedLanguage} = useSelector((state)=>state.language)
  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log("date", date);
    setShowCalendarOverlay(false);
    navigateBigCalendar(date);
  };
  const navigateBigCalendar = (date) => {
    console.log("Navigating Big Calendar to:", date);
  };

  // semain header date coustomise
  const customFormats = {
    dayFormat: "YYYY/MM/DD",
    dayHeaderFormat: (day, culture, localizer) =>
      localizer.format(day, "dddd").charAt(0).toUpperCase() +
      localizer.format(day, "dddd").slice(1),
    agendaDateFormat: (date, culture, localizer) => {
      let jsDate;

      if (typeof date === "number") {
        jsDate = new Date(date);
      } else {
        jsDate = date;
      }
      const formattedDate = localizer.format(jsDate, "YYYY/MM/DD", culture);
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    },

    //semaine
    dayRangeHeaderFormat: ({ start, end }, culture, local) =>
      local.format(start, "YYYY  MMMM (DD", culture) +
      " – " +
      local.format(
        end,
        local.eq(start, end, "month") ? "DD) " : "YYYY DD MMMM",
        culture
      ),
    dayHeaderFormat: (date, culture, localizer) => {
      const year = localizer.format(date, "YYYY", culture);
      const moth = localizer.format(date, "MMM", culture);
      const dat = localizer.format(date, "DD", culture);
      const day = localizer.format(date, "(ddd)", culture);
      const headertext = `${dat} ${moth} ${year} ${day}`;
      return headertext;
    },

    agendaHeaderFormat: ({ start, end }, culture, localizer) => {
      const startDate = localizer.format(start, "YYYY/MM/DD", culture);
      const endDate = localizer.format(end, "YYYY/MM/DD", culture);

      return `${startDate}  - ${endDate}`;
    },
  };


  const CustomHeader = ({ label, onNavigate, onView }) => {
    const handleDateChange = () => {
      console.log(selectedDate);
      setShowCalendarOverlay(!showCalendarOverlay);
    };
    console.log("wokagenda", currentView)
    const { t } = useTranslation("global");

    return (
      <div className="costom">
        <div className="rbc-toolbar d-flex justify-content-between mb-3">
          <div>
            <button
              type="button"
              className="btn-sm btn-primary btn  "
              onClick={() => onNavigate("TODAY")}
            >
              {t("Aujourd'hui")}
            </button>
            <button
              type="button"
              className="btn-sm btn-primary btn "
              onClick={() => onNavigate("PREV")}
            >
            {t("Précédente")}
            </button>
            <button
              type="button"
              className="btn-sm btn-primary btn"
              onClick={() => onNavigate("NEXT")}
            >
              {t("Suivante")}
            </button>
          </div>
          <span
            style={{ cursor: "pointer" }}
            className="border border-1 rounded-pill  px-3 py-1 "
            onClick={() => handleDateChange()}
          >
            <strong>{label}</strong>
          </span>
          <div>
            <button
              type="button"
              className="btn-sm btn-primary btn  "
              onClick={() => {
                onView("month");
                setCurrentView("month");
                setShowCalendarOverlay(false);
              }}
            >
              {t("Mois")}
            </button>
            <button
              type="button"
              className="btn-sm btn-primary btn  "
              onClick={() => {
                onView("work_week");
                setCurrentView("work_week");
                setShowCalendarOverlay(false);
              }}
            >
              {t("Semaine")}
            </button>
            <button
              type="button"
              className="btn-sm btn-primary btn "
              onClick={() => {
                setCurrentView("day");
                onView("day");
              }}
            >
              {t("Jour")}
            </button>
            <button
              type="button"
              className="btn btn-sm btn-primary "
              onClick={() => {

                setCurrentView("agenda")
                onView("agenda");
              }}
            >
              {t("Affichage Planification")}
            </button>
          </div>
        </div>
      </div>
    );
  };
  const handleViewChange = (newView) => {
    switch (newView) {
      case "Mois":
        return "month";
      case "Semaine":
        return "week";
      case "Jour":
        return "day";
      case "Affichage Planification":
        return "agenda";
      default:
        return "month";
    }
  };
useEffect(()=>{

},[setCurrentView])
  return (
    <div>
      <BigCalendar
        onNavigate={(date) => {
          setSelectedDate(date);
        }}
        localizer={localizer}
        events={allEvents}
        components={{
          toolbar: (props) => (
            <div>
              <CustomHeader {...props} />
            </div>
          ),
          agenda: {
            event: MyAgendaEvent,
          },
          day: CustomDay,
          week: {
            header: CustomWeekHeader,
          },
          month: {
            header: CustomMonthHeader,
          },
        }}
        culture={selectedLanguage=="fr"?"fr":"en"}
        date={selectedDate}
        eventPropGetter={currentView === "agenda" ? "" : calendarstyle}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={(e) => handleSelectScheduler(e)}
        messages={messages}
        style={{ height: 500 }}
        step={15}
        timeslots={2}
        views={{
          month: true,
          work_week: true,
          day: true,
          agenda: true,
        }}
        titleAccessor={(event) => (
          <div>
            <div>{event.title}</div>
            {(currentView === "Mois" || currentView === "month") && (
              <div>
                {moment(event.start).format("LT")} -{" "}
                {moment(event.end).format("LT")}
              </div>
            )}
          </div>
        )}
        formats={customFormats}
        onView={handleViewChange}
      />
      {/* mini calender css  */}
      {showCalendarOverlay && (
        <div
          style={{
            position: "absolute",
            left: "45%",
            bottom: "20%",
            width: "310px",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >
          <Calendar
            locale={selectedLanguage=="fr"?"fr":""}
            onChange={handleDateChange}
            value={selectedDate}
          />
        </div>
      )}
    </div>
  );
};

export default MainCalendar;
