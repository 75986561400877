import React, { Suspense } from "react";
import Sidebar from "../../Application/Sidebar";
import Header from "../../Application/Header";
import { Route, Routes } from "react-router-dom";
import sidestyle from "../../Application/sidebar.module.css";
import Loader from "../../../utils/Loader";
import Clients from "../screens/new_client";
import Dashboard from "../screens/Dashboard";
import Errorpage from "../../Errorpage";
 const ReportByDateTime = React.lazy(() => import("../ReportByDateTime"));
const ServiceReport = React.lazy(() => import("../ServiceReport"));
const RefferedToReport = React.lazy(() => import("../RefferedToReport"));
const Reports = React.lazy(() => import("../Reports"));

 
const ViewClients = React.lazy(() =>
  import("../screens/components/ViewClients")
);
const ProfilePage = React.lazy(() => import("../../auth/ProfilePage"));

const SpeakerStatistics = React.lazy(() => import("../SpeakerStatistics"));
const TeamReport = React.lazy(() => import("../TeamReport"));
const StatusImmigration = React.lazy(() => import("../StatusImmigration"));
const UpdateClient = React.lazy(() =>
  import("../screens/new_client/UpdateClient")
);
const Appointment = React.lazy(() =>
  import("../screens/components/newcustomer/components/Appointment")
);

function MainApp() {
  return (
    <div
      className="d-flex"
      style={{
        backgroundImage:
          "linear-gradient(to bottom, rgb(92, 94, 159), rgb(92, 94, 159) 50%, #f1eff0 50%, #f1eff0)",
      }}
    >
      <Sidebar title="admin" />
      <div className="flex-grow-1">
        <Header />
        <div
          className={`container-fluid h-100 ${sidestyle.scrollbarmainstyle} ${sidestyle.bodyadjust}`}
          style={{
            backgroundColor: "#f1eff0",
            borderTopLeftRadius: "30px",
            overflowY: "auto",
            maxHeight: "82.8vh",
          }}
        >
          <Suspense
            fallback={
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "70vh" }}
              >
                <Loader />
              </div>
            }
          >
            <Routes>
              <Route exact path="/" element={<Dashboard />} />
              <Route exact path="/clients/*" element={<Clients />} />
              <Route exact path="/viewClient/:id" element={<ViewClients />} />
              <Route exact path="/updateclient" element={<UpdateClient />} />
              <Route
                exact
                path="/updateclient/:id"
                element={<UpdateClient />}
              />
              <Route exact path="/calendar" element={<Appointment />} />
              <Route exact path="/profile" element={<ProfilePage />} />

              <Route exact path="/viewuser/:id" element={<Clients />} />

              <Route
                exact
                path="/reportByDateTime"
                element={<ReportByDateTime />}
              />
              <Route exact path="/serviceReport" element={<ServiceReport />} />
              <Route
                exact
                path="/speakerStatistics"
                element={<SpeakerStatistics />}
              />
              <Route
                exact
                path="/status_immigration"
                element={<StatusImmigration />}
              />
              <Route
                exact
                path="/refferedToReport"
                element={<RefferedToReport />}
              />

              <Route exact path="/reports" element={<Reports />} />
              <Route exact path="/teamReport" element={<TeamReport />} />
              <Route exact path="*" element={<Errorpage />} />

            </Routes>
          </Suspense>
        </div>
      </div>
    </div>
  );
}

export default MainApp;
